import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import BaseComponent from '../base/base-component';
import SODMapChart from '../components/charts/map/sod/sod-map-chart';
import ContainerBox from '../components/container-box/container-box';
import Layout from '../components/layout/layout';
import Share from '../components/share/share';
import SocialMetadata from '../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../components/social-metadata/social-metadata-enum';
import WhatToDoHome from '../components/what-to-do-home/what-to-do-home';
import { SODMeasures } from '../global/global-enum';
import '../global/locale/en-us';
import { gradeSVG } from '../global/svg/grades';
import {
  ICriteriaData,
  IPageContentProperties,
} from '../templates/criteria/criteria-interface';

interface IHomeProperties {
  readonly data: ICriteriaData;
}

export default class Home extends BaseComponent<IHomeProperties> {
  protected declareTranslateCollection(): string {
    return 'home';
  }

  public render(): ReactNode {
    const { data } = this.props;
    const pageContent: IPageContentProperties = {};
    data.homePageContent.nodes.forEach((content) => {
      pageContent[content.title] = content.value;
    });
    const {
      overallSubtitle,
      findYourState,
      findYourStateDesc,
      getTheFacts,
      getTheFactsDesc,
      getInvolved,
      getInvolvedDesc,
      CTA,
      CTALink,
    } = pageContent;

    return (
      <Layout
        title={this.translate('title')}
        description={this.translate('description')}
        subtitle={overallSubtitle}
      >
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={this.translate('title')}
          description={this.translate('description')}
          imageResourceFileName="embedded-overall-map.png"
        />
        <ContainerBox className="c-section--map" id="map">
          <div className="row c-map-details">
            <div className="col-md-8 c-map-info">
              <div className="c-map__grade">
                <span className="c-grade__text is-title">
                  {this.translate('grade')}
                </span>
                {gradeSVG}
                <span className="c-grade__text">
                  {this.translate('gradeText')}
                </span>
              </div>
            </div>
            <div className="col-md-4 c-map-share u-hide-mobile">
              <Share
                absoluteLink=""
                embeddedLink="/embedded/map/overall"
                imageResourceFileName="embedded-overall-map.png"
              />
            </div>
          </div>
          <SODMapChart
            measure={SODMeasures.GRAND_TOTAL}
            data={data}
            isTooltip={true}
          />
          <div className="c-map-share is-full u-hide-desktop">
            <Share
              absoluteLink=""
              embeddedLink="/embedded/map/overall"
              imageResourceFileName="embedded-overall-map.png"
            />
          </div>
        </ContainerBox>
        <ContainerBox className="c-section--todo">
          <WhatToDoHome
            items={[
              {
                title: findYourState,
                description: findYourStateDesc,
              },
              {
                title: getTheFacts,
                description: getTheFactsDesc,
              },
              {
                title: getInvolved,
                description: getInvolvedDesc,
                moreInfoLink: CTALink,
                moreInfoTitle: CTA,
              },
            ]}
          />
        </ContainerBox>
      </Layout>
    );
  }
}

export const query = graphql`
  query GetGrandTotalData {
    all: allSheetDataGrandTotal {
      nodes {
        code
        state
        grade
      }
    }
    allParticipation: allSheetDataParticipation(
      filter: { cycle: { eq: "Overall" }, component: { eq: "Total" } }
    ) {
      nodes {
        code
        state
        grade
      }
    }
    allCompetition: allSheetDataCompetition(
      filter: { cycle: { eq: "Overall" }, component: { eq: "Total" } }
    ) {
      nodes {
        code
        state
        grade
      }
    }
    allRepresentation: allSheetDataRepresentation(
      filter: { cycle: { eq: "Overall" }, component: { eq: "Total" } }
    ) {
      nodes {
        code
        state
        grade
      }
    }
    allPolarization: allSheetDataPolarization(
      filter: { cycle: { eq: "Overall" }, component: { eq: "Total" } }
    ) {
      nodes {
        code
        state
        grade
      }
    }
    homePageContent: allSheetDataHomepageContent {
      nodes {
        title
        value
      }
    }
  }
`;
